* {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  outline: 0;
  border: 0;
  background: none;
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;
}
body,
html {
  height: auto;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: 100;
  display: block;
  margin: 0 auto;
}
ol,
ul,
li,
i {
  font-style: normal;
  list-style: none;
  padding: 0;
  margin: 0;
}

img {
  border: 0;
}
a {
  background-color: transparent;
  display: inline-block;
  text-decoration: none;
}
a:active,
a:hover {
  outline: 0;
}
button {
  overflow: visible;
}
input {
  line-height: normal;
  background-color: transparent;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  height: auto;
}

input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

input:-webkit-autofill {
  background-color: #f6f6f6 !important;
  background-image: none !important;
  box-shadow: 0 0 0px 40px white inset;
}

#root {
  width: 100%;
  height: auto;
  position: relative;
}
/* bar */
/* body::-webkit-scrollbar{
    width: 6px;
    height: 4px;
}

body::-webkit-scrollbar-track {
    background-color: gba(242,242,242,0);
}

body::-webkit-scrollbar-thumb {
    background-color: #BEBEBE;
    border-radius: 6px;
}

body::-webkit-scrollbar-button {
    background-color: #ddd;
    display: none;
} */
