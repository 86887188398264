.MuiTimeline-root .MuiTimeline-alignLeft {
  padding-left: 0 !important;
}
.MuiTimelineItem-missingOppositeContent:before {
  display: none;
}
/* header svgIcon */
.MuiSvgIcon-root {
  font-size: 20px !important;
  color: white;
  background-color: var(--main-color);
}
/* line  */
.MuiTimelineConnector-root {
  margin-top: 0;
  height: 20px !important;
  background-color: rgba(210, 210, 210, 0.6) !important;
}

/* Header dot */
.timeline_header {
  min-height: 30px !important;
  /* header dot size */
  .timeline_header_dot {
    margin: 0;
    padding: 6px;
    border: 3px solid var(--main-color);
    background-color: var(--main-color);
  }
  .timeline_header_line {
    margin: 0 !important;
  }
  .timeline_header_connector {
    padding: 0px !important;
  }
  .timeline_header_content {
    padding-top: 2px;
    .timeline_header_title {
      font-size: 20px;
    }
    .timeline_header_text {
      font-size: 16px;
      color: var(--main-text-color);
    }
  }
}

/* Item dot */
.timeline_item {
  padding-left: 11px;
  min-height: 0px !important;
  .timeline_item_separator {
    .timeline_item_dot {
      margin: 3px;
      padding: 2px;
      border: 3px solid var(--main-color);
      background-color: transparent;
    }
  }
  .timeline_item_content {
    .timeline_item_title {
      margin-top: -6px;
      padding-top: 0;
      font-size: 16px;
    }
    .timeline_item_subtitle {
      font-size: 14px;
      padding-bottom: 5px;
    }
    .timeline_item_text {
      font-size: 14px;
      color: var(--main-text-color);
      padding-bottom: 10px;
    }
  }
}
