.section {
  padding: 40px;
  .title {
    width: 100%;
  }
  .custom_tabs {
    .customTabs_item {
      padding: 0;
      min-width: 0;
      margin-right: 20px;
      text-transform: inherit;
      font-size: 14px;
      font-weight: normal;
      outline-width: 0;
      .Mui-selected {
        color: var(--main-color);
        font-weight: bold;
      }
    }
    .active {
      color: var(--main-color);
      font-weight: bold;
    }
  }
  .card {
    height: 90%;
    max-width: 80%;
    box-shadow: 0 0 48 0 rgba(4, 6, 4, 0.08);
    border-radius: 5px;
    overflow: hidden;
    outline-width: 0;
    padding: 20px;
    margin: 10px auto;
    background-color: rgba(248, 248, 248, 0.8);
    .MuiButtonBase-root {
      outline: none;
    }
    .img {
      width: 100%;
      height: 140px !important;
    }

    .MuiCardContent-root {
      padding: 0;
    }
    .title {
      font-size: 14px;
      font-weight: bold;
      margin-top: 20px;
    }
    .portfolio_des {
      margin-bottom: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}
.MuiDialog-paperWidthSm {
  max-width: 800px !important;
}
