.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  background-color: black;
  .name {
    padding-left: 30px;
    color: rgb(235, 235, 235);
    font-weight: 550;
  }
  .title {
    padding-right: 30px;
    color: #aaa;
    font-size: 14px;
  }
}
