/* variant： call it by: var(name) */
:root {
  --main-color: #0891b2; /* or #FBB50E */
  --main-text-color: #424242;
}

body {
  margin-top: 40px;
  z-index: 99;
}
.side-bar {
  position: static;
  /* top: 10px; */
  padding: 0 10px 30px 10px;
}
.app_right {
  /* position: fixed; */
  padding: 0 10px 50px 15px;
}
.header {
  /* position: fixed; */
  /* top: 10px; */
}
.main {
  /* position: fixed; */

  margin: 30px 0;
}

.footer {
}
.container_shadow {
  border-radius: 7px;
  box-shadow: 0 2px 92px 0 rgba(0, 0, 0, 0.13);
  -webkit-box-shadow: 0 2px 92px 0 rgba(0, 0, 0, 0.13);
  -moz-box-shadow: 0 2px 92px 0 rgba(0, 0, 0, 0.13);
  overflow-x: hidden;
  background-color: white;
}
