.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  .header_right {
    margin-right: 10px;
    color: black;
    background-color: transparent;
  }
}
