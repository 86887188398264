.home {
  .card {
    padding: 40px 30px;
    border: none;
    .card_content {
      padding: 10px 20px;
      .card_item {
        padding: 8px 0px;
        font-size: 14px;
        color: var(--main-text-color);
        text-align: left;
        .skill_title {
          color: black;
          font-weight: 600;
        }
        .skill_item {
          padding-left: 10px;
        }
      }
    }
  }
  .skills {
    background-color: rgb(248, 248, 248);
    padding: 40px 30px;
    .paper_container {
      margin: 15px 10px;
      .paper {
        max-width: 240px;
        border-radius: 15px;
        padding: 20px;
        margin: 0 auto;
        .paper_title {
          font-size: 15px;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .paper_item {
          font-size: 14px;
          color: var(--main-text-color);
          padding: 5px;
          &::before {
            display: inline-block;
            content: '';
            width: 8px;
            height: 8px;
            margin: 0px 10px;
            border: 2px solid var(--main-color);
            border-radius: 50%;
          }
        }
      }
    }
  }
}
