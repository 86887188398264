.experience {
  padding-top: 30px;
  .title {
    margin-left: 40px;
  }
  .card {
    padding: 20px;
    border: none;
    &:nth-child(even) {
      background-color: #fff;
    }
    &:nth-child(odd) {
      background-color: rgb(248, 248, 248);
    }
    .card_content {
      padding: 10px 20px;
      .title {
        padding: 8px 0px;
        font-size: 16px;
        font-weight: bold;
        .job {
          padding-right: 50px;
        }
        .employer {
          padding-right: 50px;
        }
        .time {
          font-weight: normal;
          font-size: 14px;
          color: #555;
        }
        .des {
          font-size: 14px;
          padding-top: 10px;
          padding-left: 20px;
          color: #555;
          font-style: oblique;
        }
      }
      .timeline {
        padding-top: 20px;
        .resp {
          color: black;
          font-weight: bold;
          margin-bottom: 10px;
          font-style: oblique;
          color: var(--main-color);
        }
        .resp_item {
          font-weight: normal;
          color: var(--main-text-color);
          font-size: 14px;
        }
      }
    }
  }
}
